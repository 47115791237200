import axios from "axios";
// import {UserModel} from '../../../models/UserModel'

const AUTH_LOGIN_URL = `/users/login`;
const AUTH_VERIFY_TOKEN = `/users/verify-token`;
const AUTH_RESET_PASSWORD = `/users/editpassword/`;
const REQUEST_PASSWORD_URL = `/users/forgotpassword`;

// Server should return AuthModel
export function login(username, password) {
  return axios.post(AUTH_LOGIN_URL, { username, password });
}
// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  return axios.get(AUTH_VERIFY_TOKEN);
}

export const resetPassword = ({ code, newPassword }) => {
  return axios.post(AUTH_RESET_PASSWORD + code, { password: newPassword });
};
