import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MainLayout from "../components/mainLayout";
import Dashboard from "../modules/dashboard";
import Spinner from "../components/spinner";
export function PrivateRoutes() {
  const TheSolution = lazy(() => import("../modules/the-solution"));
  const PartnersPage = lazy(() => import("../modules/partners/PartnersPage"));
  const NewsPage = lazy(() => import("../modules/news/NewsPage"));
  const ProjectsPage = lazy(() => import("../modules/projects/ProjectsPage"));
  const UsersPage = lazy(() => import("../modules/users/UsersPage"));
  return (
    <Suspense fallback={<Spinner open={true} />}>
      <MainLayout>
        <Switch>
          <Route path="/the-solution" component={TheSolution} />
          <Route path="/partners" component={PartnersPage} />
          <Route path="/users" component={UsersPage} />
          <Route path="/news" component={NewsPage} />
          <Route path="/projects" component={ProjectsPage} />
          <Route path="/dashboard" component={Dashboard} />
          <Redirect from="/" to="/dashboard" component={Dashboard} />
          <Redirect from="/auth" to="/" />
        </Switch>
      </MainLayout>
    </Suspense>
  );
}
