import { Switch, Redirect, Route } from "react-router-dom";
import { PrivateRoutes } from "./private-routes";
import { useMemo } from "react";
import { useAuthRole } from "../components/hooks/useAuthRole";
import { Logout, AuthPage } from "../modules/auth";

const Routes = () => {
  const auth = useAuthRole();
  const unauthenticatedRoutes = useMemo(() => {
    if (!auth) {
      return (
        <Route>
          <AuthPage />
        </Route>
      );
    }
    return <Redirect from="/auth" to="/" />;
  }, [auth]);

  const authenticatedRoutes = useMemo(() => {
    if (!auth) {
      return <Redirect to="/auth/login" />;
    }
    return <PrivateRoutes />;
  }, [auth]);

  return (
    <Switch>
      <Route path="/logout" component={Logout} />
      {unauthenticatedRoutes}
      {authenticatedRoutes}
    </Switch>
  );
};

export default Routes;
