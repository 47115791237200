const colors = {
  transparency: {
    primary: "transparent",
    card: "50",
  },
  // backgroundColor: "#211e1e",
  // primary: "#42a242",
  // secondary: "#333",
  // error: "#b92945",
  // danger: "#dd3660",
  // disabled: "#e5e3de",
  // warning: "#f3b359",
  // success: "#4cb34d",
  // textPrimary: "#FFFFFF",
  // textSecondary: "#747476",
  // textDisabled: "#A5A5A5",
  // black: "#211e1e",
  // lightBlack: "#2a2828",
  // white: "#FFFFFF",
  // gray: "#4c4b4b",
  // lightGray: "#928f8f",
  // secondColor: "#bf8a32",
  // lightBlue: "#6799ee",
  // lightCheckBox: "#3f8479",
  // darkCheckBox : "#154840",

  backgroundColor: "#ffffff",
  primary: "#01873d",
  secondary: "#333",
  error: "#e34765",
  danger: "#dd3660",
  disabled: "#e5e3de",
  warning: "#f3b359",
  success: "#4cb34d",
  textPrimary: "#000000",
  textSecondary: "#747476",
  textDisabled: "#A5A5A5",
  black: "#211e1e",
  lightBlack: "#e4e5e6",
  white: "#FFFFFF",
  gray: "#4c4b4b",
  lightGray: "#928f8f",
  secondColor: "#bf8a32",
  lightBlue: "#4a7ccf",
  lightCheckBox: "#4a7ccf",
  darkCheckBox: "#094b8a",
};
export default colors;
