import { Redirect, Route, Switch } from "react-router-dom";
import { Box } from "@material-ui/core";
import Login from "./components/Login";
import ForgotPassword from "./components/Forgot-password";
import ResetPassword from "./components/Reset-password";

export function AuthPage() {
  return (
    <Box>
      <Switch>
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/forgot-password" component={ForgotPassword} />
        <Route path="/auth/reset-password/:code" component={ResetPassword} />
        <Redirect from="/auth" exact={true} to="/auth/login" />
        <Redirect to="/auth/login" />
      </Switch>
    </Box>
  );
}
