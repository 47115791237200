import Swal from "sweetalert2";
import colors from "../../../constant/colors";
export default function SweetAlert({
  type = "error",
  icon = "error",
  title = "Title",
  text = "Text",
  showCancelButton = false,
  confirmButtonText,
}) {
  switch (type) {
    case "error":
      return Swal.fire({
        icon: icon,
        title: title,
        text: text,
      });

    case "confirm":
      return Swal.fire({
        icon: icon,
        title: title,
        text: text,
        showCancelButton: showCancelButton,
        confirmButtonColor: colors.primary,
        cancelButtonColor: colors.error,
        confirmButtonText: confirmButtonText,
      });

    default:
      break;
  }
}
