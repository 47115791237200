import React, { useMemo } from "react";
import { Box, Typography, Divider } from "@material-ui/core";

const SectionContainerTitle = ({ title, children, secondaryText }) => {
  const SectionContainerTitleMemo = useMemo(() => {
    return (
      <Box mt={5}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              <Box
                fontWeight="fontWeightBold"
                style={{ textTransform: "uppercase" }}
              >
                {title}
              </Box>
            </Typography>
          </Box>
          {secondaryText ? (
            <Box>
              <Typography variant="body2" color="secondary">
                {secondaryText}
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box>
          <Divider orientation="horizontal" light />
        </Box>
        <Box mt={3}>{children}</Box>
      </Box>
    );
  }, [children, secondaryText, title]);
  return SectionContainerTitleMemo;
};

export default SectionContainerTitle;
