import React, { useState, useEffect } from "react";
import SectionContainerTitle from "../../components/section-container-title";
import { styled } from "@material-ui/styles";
import {
  Grid,
  Typography,
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
// import { useTranslation } from "react-i18next";
// import { useHistory } from "react-router-dom";
import Chart from "react-apexcharts";
import axios from "axios";
import moment from "moment";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Dashboard() {
  const [contentStatusChartData, setContentStatusChartData] = useState([]);
  const [contentStatusChartLabels, setContentStatusChartLabels] = useState([]);
  const [websiteViewsChartData, setWebsiteViewsChartData] = useState([]);
  const [websiteViewsChartLabels, setWebsiteViewsChartLabels] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [userLogData, setUserLogData] = useState({});
  const [barChart, setBarChart] = useState({});
  const [websiteViewsChart, setWebsiteViewsChart] = useState({});
  const [todayViews, setTodayViews] = useState();
  const [monthlyAverage, setMonthlyAverage] = useState();
  const [formSubmitted, setFormSubmitted] = useState();
  const [websiteViewsBy, setWebsiteViewsBy] = useState("MONTH");
  const months = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const fetchUserLog = async () => {
    try {
      await axios
        .post("/dashboard/user-logs", { limit: 5 })
        .then((_response) => {
          // console.log(_response.data)
          if (_response && _response.data.data.length > 0) {
            setUserLogData(_response.data.data);
            setIsDataLoaded(true);
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTodayViews = async (e) => {
    try {
      await axios
        .get("/dashboard/total-view-today")
        .then((_response) => {
          setTodayViews(_response.data);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMonthlyAverage = async (e) => {
    try {
      await axios
        .get("/dashboard/total-monthly-average")
        .then((_response) => {
          if (_response.data) {
            setMonthlyAverage(_response.data);
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const totalFormSubmitted = async (e) => {
    try {
      await axios
        .get("/dashboard/total-form-submitted")
        .then((_response) => {
          if (_response.data) {
            setFormSubmitted(_response.data);
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchChartData = async (e) => {
    await axios
      .post("/dashboard/content-status-chart")
      .then((_response) => {
        if (_response && _response.data) {
          setContentStatusChartData(_response.data._chartData);
          setContentStatusChartLabels(_response.data.label);
        }
      })
      .catch((err) => console.log(err));
  };
  const fetchWebsiteViewsChartData = async (e) => {
    await axios
      .post("/dashboard/website-views", { by: websiteViewsBy })
      .then((_response) => {
        if (_response && _response.data) {
          if (websiteViewsBy === "MONTH") {
            let monthName = [];
            _response.data.data.forEach(async (element) => {
              console.log("element", element);
              await monthName.push(months[element]);
            });
            setWebsiteViewsChartLabels(monthName);
          } else {
            setWebsiteViewsChartLabels(_response.data.data);
          }
          setWebsiteViewsChartData(_response.data.count);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchUserLog();
    fetchChartData();
    fetchTodayViews();
    fetchMonthlyAverage();
    totalFormSubmitted();
    fetchWebsiteViewsChartData();
    setIsDataLoaded(true);
  }, []);

  useEffect(() => {
    if (
      contentStatusChartData &&
      contentStatusChartData.length > 0 &&
      contentStatusChartLabels &&
      contentStatusChartLabels.length > 0
    ) {
      setIsDataLoaded(true);

      setBarChart({
        series: [
          {
            name: "Status",
            data: contentStatusChartData,
          },
        ],
        options: {
          colors: ["#77BC8F", "#2FB960", "#15873D"],
          chart: {
            height: 350,
            type: "bar",
            events: {
              click: function (chart, w, e) {
                // console.log(chart, w, e)
              },
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "60%",
              distributed: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: contentStatusChartLabels,
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
        },
      });
    }
    if (
      websiteViewsChartData &&
      websiteViewsChartData.length > 0 &&
      websiteViewsChartLabels &&
      websiteViewsChartLabels.length > 0
    ) {
      setIsDataLoaded(true);

      setWebsiteViewsChart({
        series: [
          {
            name: "Views",
            data: websiteViewsChartData,
          },
        ],
        options: {
          colors: ["#77BC8F", "#2FB960", "#15873D"],
          chart: {
            height: 350,
            width: "100%",
            type: "bar",
            events: {
              click: function (chart, w, e) {
                // console.log(chart, w, e)
              },
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "60%",
              distributed: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: websiteViewsChartLabels,
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
        },
      });
    }
  }, [
    websiteViewsChartData,
    websiteViewsChartLabels,
    contentStatusChartData,
    contentStatusChartLabels,
  ]);
  useEffect(() => {}, [barChart, isDataLoaded]);
  return (
    <>
      <SectionContainerTitle title="Dashboard">
        {isDataLoaded &&
        barChart &&
        barChart.options &&
        barChart.series &&
        websiteViewsChart &&
        websiteViewsChart.options &&
        websiteViewsChart.series &&
        contentStatusChartData &&
        contentStatusChartData.length > 0 &&
        contentStatusChartLabels &&
        contentStatusChartLabels.length > 0 &&
        websiteViewsChartData &&
        websiteViewsChartData.length > 0 &&
        websiteViewsChartLabels &&
        websiteViewsChartLabels.length > 0 ? (
          <Grid container spacing={3}>
            <Grid container item xs={12} sm={9}>
              <Grid container item xs={12} sm={4}>
                <Box p={2} mt={5} mb={5}>
                  <Box p={2} style={{ backgroundColor: "#DDD", color: "#333" }}>
                    <Typography variant="h5">Content Status</Typography>
                  </Box>
                  <Chart
                    options={barChart.options}
                    series={barChart.series}
                    type="bar"
                  />
                </Box>
              </Grid>
              <Grid container item xs={12} sm={8}>
                <Box p={2} mt={5} mb={5}>
                  <Box p={2} style={{ backgroundColor: "#DDD", color: "#333" }}>
                    <Typography variant="h5">Latest Activity</Typography>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="left">Description</TableCell>
                          <TableCell align="left">Action</TableCell>
                          <TableCell align="left">Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userLogData && userLogData.length > 0
                          ? userLogData.map((row) => (
                              <StyledTableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                  {row.user.firstName} {row.user.lastName}
                                </TableCell>
                                <TableCell align="left">
                                  {row.description}
                                </TableCell>
                                <TableCell align="left">{row.action}</TableCell>
                                <TableCell align="left">
                                  {moment(row.createdAt).format(
                                    "YYYY-MM-DD HH:mm"
                                  )}
                                </TableCell>
                              </StyledTableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>

              <Grid container item xs={12}>
                <Box p={2} mt={5} mb={5}>
                  <Box p={2} style={{ backgroundColor: "#DDD", color: "#333" }}>
                    <Typography variant="h5">Website Views</Typography>
                  </Box>
                  <Chart
                    options={websiteViewsChart.options}
                    series={websiteViewsChart.series}
                    type="bar"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    p={2}
                    mt={5}
                    style={{ backgroundColor: "#DDD", color: "#333" }}
                  >
                    <Typography variant="h5" align="center">
                      Total Views Today
                    </Typography>
                  </Box>
                  <Box
                    py={5}
                    style={{ backgroundColor: "#EEE", color: "#111" }}
                  >
                    <Typography variant="h3" align="center">
                      {todayViews && todayViews._data}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    p={2}
                    mt={5}
                    style={{ backgroundColor: "#DDD", color: "#333" }}
                  >
                    <Typography variant="h5" align="center">
                      Average Monthly Views
                    </Typography>
                  </Box>
                  <Box
                    py={5}
                    style={{ backgroundColor: "#EEE", color: "#111" }}
                  >
                    <Typography variant="h3" align="center">
                      {monthlyAverage && monthlyAverage.avg.toFixed(1)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    p={2}
                    mt={5}
                    style={{ backgroundColor: "#DDD", color: "#333" }}
                  >
                    <Typography variant="h5" align="center">
                      Forms Submitted
                    </Typography>
                  </Box>
                  <Box
                    py={5}
                    style={{ backgroundColor: "#EEE", color: "#111" }}
                  >
                    <Typography variant="h3" align="center">
                      {formSubmitted && formSubmitted._data}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </SectionContainerTitle>
      {/* <Spinner open={isLoading} /> */}
      {/* <CustomAlert
        open={openAlert}
        text={toastText}
        autoHideDuration={toastTime}
        severity={toastType}
        onClose={handleAlertClose}
      /> */}
    </>
  );
}
