import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  makeStyles,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import Spinner from "../../../components/spinner";
import { requestPassword } from "../redux/AuthCRUD";
import CustomLink from "../../../components/custom-link";
import colors from "../../../../constant/colors";
import { useHistory } from "react-router";
import SweetAlert from "../../../components/sweetAlert";
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.light,
  },
}));

const initialValues = {
  email: "",
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const validationSchema = yup.object().shape({
    email: yup.string().email().required(t("required")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      try {
        setIsLoading(true);
        setTimeout(() => {
          requestPassword(values.email)
            .then(() => {
              setIsLoading(false);
              setSubmitting(false);
              history.replace("/auth/login");
            })
            .catch(() => {
              setIsLoading(false);
              setSubmitting(false);
              setStatus("Server Error");
              SweetAlert({
                type: "error",
                icon: "error",
                title: "Ops...",
                text: "SomtThing went Wrong",
              });
            });
        }, 100);
      } catch (err) {
        setIsLoading(false);
        setSubmitting(false);
        setStatus("Server Error");
        SweetAlert({
          type: "error",
          icon: "error",
          title: "Ops...",
          text: "SomtThing went Wrong",
        });
      }
    },
  });

  return (
    <Box minWidth={450} maxWidth={450} className="absolute-m-t-l">
      <Box textAlign="center" mb={3}>
        Logo
      </Box>
      <Box>
        <Card className={classes.card}>
          <Box clone p={2}>
            <CardContent>
              <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary">
                      {t("forgotPassword")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      error={formik.touched.email && formik.errors.email}
                      style={{ width: "100%" }}
                    >
                      <TextField
                        fullWidth
                        label={t("email")}
                        name="email"
                        error={formik.touched.email && formik.errors.email}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />

                      <FormHelperText>
                        {formik.touched.email && formik.errors.email}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" mt={3}>
                      <Box flexGrow={1}>
                        <CustomLink
                          to="/auth/login"
                          name={t("backToLogin")}
                          color={colors.lightBlue}
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          {t("send")}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Box>
        </Card>
      </Box>
      {formik.status && (
        <Typography variant="body2" color="error">
          {formik.status}
        </Typography>
      )}
      <Spinner open={isLoading} />
    </Box>
  );
};

export default ForgotPassword;
