import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  makeStyles,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import Spinner from "../../../components/spinner";
import { useHistory } from "react-router";
import { resetPassword } from "../redux/AuthCRUD";
import SweetAlert from "../../../components/sweetAlert";
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.light,
  },
}));

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const { code } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const validationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .min(7, "Minimum 10 characters")
      .max(50, "Maximum 50 characters")
      .required(),
    confirmPassword: yup
      .string()
      .min(7, "Minimum 10 characters")
      .max(50, "Maximum 50 characters")
      .test(
        "is-password-match",
        "Password does not match",
        (value, context) => {
          const formValues = context.parent;
          if (formValues.newPassword) {
            return formValues.newPassword === value;
          }
          return false;
        }
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      try {
        setIsLoading(true);
        setTimeout(() => {
          resetPassword({ code, newPassword: values.confirmPassword })
            .then(() => {
              setIsLoading(false);
              setSubmitting(false);
              history.replace("/auth/login");
            })
            .catch(() => {
              setIsLoading(false);
              setSubmitting(false);
              setStatus("Server Error");
              SweetAlert({
                type: "error",
                icon: "error",
                title: "Ops...",
                text: "SomtThing went Wrong",
              });
            });
        }, 100);
      } catch (err) {
        setIsLoading(false);
        setSubmitting(false);
        setStatus("Server Error");
        SweetAlert({
          type: "error",
          icon: "error",
          title: "Ops...",
          text: "SomtThing went Wrong",
        });
      }
    },
  });

  return (
    <Box minWidth={450} maxWidth={450} className="absolute-m-t-l">
      <Box textAlign="center" mb={3}>
        Logo
      </Box>
      <Box>
        <Card className={classes.card}>
          <Box clone p={2}>
            <CardContent>
              <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary">
                      {t("resetPassword")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      error={
                        formik.touched.newPassword && formik.errors.newPassword
                      }
                      style={{ width: "100%" }}
                    >
                      <TextField
                        fullWidth
                        label={t("newPassword")}
                        name="newPassword"
                        type="password"
                        error={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                        }
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />

                      <FormHelperText>
                        {formik.touched.newPassword &&
                          formik.errors.newPassword}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      error={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      style={{ width: "100%" }}
                    >
                      <TextField
                        fullWidth
                        label={t("confirmPassword")}
                        name="confirmPassword"
                        type="password"
                        error={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                        }
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />

                      <FormHelperText>
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" mt={3}>
                      <Button variant="contained" color="primary" type="submit">
                        {t("send")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Box>
        </Card>
      </Box>
      {formik.status && (
        <Typography variant="body2" color="error">
          {formik.status}
        </Typography>
      )}
      <Spinner open={isLoading} />
    </Box>
  );
};

export default ResetPassword;
