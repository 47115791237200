import { CircularProgress, Box } from "@material-ui/core";
import React, { useMemo } from "react";
import colors from "../../../constant/colors";

const Spinner = ({ open = true }) => {
  const SpinnerMemo = useMemo(() => {
    return (
      <Box
        className="absolute-m-t-l"
        width={1}
        height={1}
        style={{
          backgroundColor: `${colors.backgroundColor}${colors.transparency.card}`,
        }}
      >
        <Box
          style={{
            background: `${colors.lightBlack}`,
            borderRadius: "5px",
            width: "200px",
            height: "200px",
            padding: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50% , -50%)",
          }}
        >
          <Box textAlign="center" className="absolute-m-t-l" p={3}>
            <CircularProgress color="primary" />
          </Box>
        </Box>
      </Box>
    );
  }, []);
  return open && SpinnerMemo;
};

export default Spinner;
