import React from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  TextField,
  makeStyles,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import CustomLink from "../../../components/custom-link";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { login } from "../redux/AuthCRUD";
import * as auth from "../redux/AuthRedux";
import { useDispatch } from "react-redux";
import Spinner from "../../../components/spinner";
import colors from "../../../../constant/colors";
import SweetAlert from "../../../components/sweetAlert";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.light,
  },
}));

const initialValues = {
  email: "developer@webntech.ae",
  password: "Wnt@1234",
};

const Login = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(t("required")),
    password: yup
      .string()
      .min(7, `${t("mininum")} 6 ${t("characters")}`)
      .max(50, `${t("maximum")} 50 ${t("characters")}`)
      .required(t("required")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting, setStatus }) => {
      try {
        setIsLoading(true);
        login(values.email, values.password)
          .then(({ data: { token } }) => {
            setIsLoading(false);
            dispatch(auth.actions.login(token));
            document.location.reload();
          })
          .catch(() => {
            setIsLoading(false);
            setSubmitting(false);
            setStatus("Server Error");
            SweetAlert({
              type: "error",
              icon: "error",
              title: "Ops...",
              text: "SomtThing went Wrong",
            });
          });
      } catch (error) {
        setStatus("Server Error");
        setIsLoading(false);
        setSubmitting(false);
        SweetAlert({
          type: "error",
          icon: "error",
          title: "Ops...",
          text: "SomtThing went Wrong",
        });
      }
    },
  });

  return (
    <>
      <Box minWidth={450} className="absolute-m-t-l">
        <Box textAlign="center" mb={3}>
          Logo
        </Box>
        <Card className={classes.card} elevation={15}>
          <Box p={2}>
            <CardContent>
              <form
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      color="textPrimary"
                      className="text-uppercase"
                    >
                      {t("login")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      style={{
                        width: "100%",
                      }}
                      error={formik.touched.email && formik.errors.email}
                    >
                      <TextField
                        fullWidth
                        label={t("username")}
                        name="email"
                        error={formik.touched.email && formik.errors.email}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      <FormHelperText>
                        {formik.touched.email && formik.errors.email}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      style={{
                        width: "100%",
                      }}
                      error={formik.touched.email && formik.errors.email}
                    >
                      <TextField
                        fullWidth
                        label={t("password")}
                        name="password"
                        error={
                          formik.touched.password && formik.errors.password
                        }
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      <FormHelperText>
                        {formik.touched.password && formik.errors.password}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" mt={3}>
                      <Box flexGrow={1}>
                        <CustomLink
                          to="/auth/forgot-password"
                          name={t("forgotPassword")}
                          color={colors.lightBlue}
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          {t("login")}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </form>
              {formik.status && (
                <Typography variant="body2" color="error">
                  {formik.status}
                </Typography>
              )}
            </CardContent>
          </Box>
        </Card>
      </Box>
      <Spinner open={isLoading} />
    </>
  );
};

export default Login;
