import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./constant/theme";
import { StylesProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { I18nextProvider } from "react-i18next";
import i18n from "./setup/i18n";
import * as _redux from "./setup";
import axios from "axios";
import store, { persistor } from "./setup/redux/Store";
import Spinner from "./app/components/spinner";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./styles/global.scss";
_redux.setupAxios(axios, store);

const { PUBLIC_URL } = process.env;
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<Spinner open={true} />}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <StylesProvider>
            <CssBaseline>
              <App basename={PUBLIC_URL} />
            </CssBaseline>
          </StylesProvider>
        </ThemeProvider>
      </I18nextProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
