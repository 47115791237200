import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useAuthRole = () => {
  const auth = useSelector((state) => {
    return state.auth?.user;
  });

  const role = useMemo(() => {
    if (auth) {
      return auth;
    }
    return null;
  }, [auth]);

  return role;
};
