import React, { useMemo } from "react";
import {
  Box,
  Drawer,
  Divider,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import colors from "../../../constant/colors";
import menuData from "../../../constant/menu.json";
import { map } from "lodash";
import { useHistory } from "react-router";
import { checkIsActive } from "../../../helpers/routerHelper";
import DashboardIcon from "@material-ui/icons/Dashboard";
import InfoIcon from "@material-ui/icons/Info";
import GroupIcon from "@material-ui/icons/Group";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AddBoxIcon from "@material-ui/icons/AddBox";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

export default function MenuDrawer({ open, onClose }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const DrawerHeader = useMemo(() => {
    return (
      <Box
        style={{ cursor: "pointer" }}
        onClick={onClose}
        position="relative"
        display="flex"
        width={1}
      >
        <Box>
          <img
            width="80px"
            alt="green chain log"
            src="/images/green-chain-logo.png"
          />
        </Box>
        <Box
          flexGrow={1}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          textAlign="right"
        >
          <Box display="inline-block">
            <Tooltip title="Close" arrow>
              <ArrowBackIosIcon color="primary" fontSize="medium" />
            </Tooltip>
          </Box>
        </Box>
      </Box>
    );
  }, [onClose]);

  const DrawerBody = useMemo(() => {
    return (
      menuData &&
      menuData.length > 0 &&
      map(menuData, (item, idx) => {
        return (
          <List key={item.title}>
            <>
              {item.links &&
                item.links.length > 0 &&
                map(item.links, (link) => {
                  return (
                    <ListItem
                      button
                      key={link.name + idx}
                      onClick={() => {
                        onClose();
                        history.replace(link.to);
                      }}
                    >
                      <ListItemIcon>
                        {link.icon === "add" ? (
                          <AddBoxIcon
                            style={{
                              cursor: "pointer",
                              color: !checkIsActive(pathname, link.to)
                                ? colors.textPrimary
                                : colors.lightBlue,
                            }}
                          />
                        ) : link.icon === "dashboard" ? (
                          <DashboardIcon
                            style={{
                              cursor: "pointer",
                              color: !checkIsActive(pathname, link.to)
                                ? colors.textPrimary
                                : colors.lightBlue,
                            }}
                          />
                        ) : link.icon === "info" ? (
                          <InfoIcon
                            style={{
                              cursor: "pointer",
                              color: !checkIsActive(pathname, link.to)
                                ? colors.textPrimary
                                : colors.lightBlue,
                            }}
                          />
                        ) : link.icon === "partner" ? (
                          <GroupIcon
                            style={{
                              cursor: "pointer",
                              color: !checkIsActive(pathname, link.to)
                                ? colors.textPrimary
                                : colors.lightBlue,
                            }}
                          />
                        ) : link.icon === "news" ? (
                          <ImportContactsIcon
                            style={{
                              cursor: "pointer",
                              color: !checkIsActive(pathname, link.to)
                                ? colors.textPrimary
                                : colors.lightBlue,
                            }}
                          />
                        ) : link.icon === "project" ? (
                          <AccountTreeIcon
                            style={{
                              cursor: "pointer",
                              color: !checkIsActive(pathname, link.to)
                                ? colors.textPrimary
                                : colors.lightBlue,
                            }}
                          />
                        ) : link.icon === "user" ? (
                          <AccountBoxIcon
                            style={{
                              cursor: "pointer",
                              color: !checkIsActive(pathname, link.to)
                                ? colors.textPrimary
                                : colors.lightBlue,
                            }}
                          />
                        ) : (
                          <TrackChangesIcon
                            style={{
                              cursor: "pointer",
                              color: !checkIsActive(pathname, link.to)
                                ? colors.textPrimary
                                : colors.lightBlue,
                            }}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          cursor: "pointer",
                          color: !checkIsActive(pathname, link.to)
                            ? colors.textPrimary
                            : colors.lightBlue,
                        }}
                        primary={t(link.name)}
                      />
                    </ListItem>
                  );
                })}

              <Divider />
            </>
          </List>
        );
      })
    );
  }, [history, onClose, pathname, t]);

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box mx={4} my={2}>
        {DrawerHeader}
        {DrawerBody}
      </Box>
    </Drawer>
  );
}
