import { createTheme } from "@material-ui/core/styles";
import colors from "./colors";
const theme = createTheme({
  direction: localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr",
  palette: {
    background: {
      default: colors.backgroundColor,
      light: colors.lightBlack,
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
    warning: {
      main: colors.warning,
    },
    disabled: {
      main: colors.disabled,
    },
    success: {
      main: colors.success,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
      disabled: colors.textDisabled,
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: colors.lightBlack,
      },
    },
    MuiDropzoneArea: {
      root: {
        backgroundColor: colors.lightBlack,
      },
      icon: {
        color: colors.primary,
      },
    },
    MuiDivider: {
      light: {
        backgroundColor: colors.gray,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&.Mui-checked": {
          color: colors.lightCheckBox,
        },
      },
    },
    MuiTablePagination: {
      root: {
        flexDirection: "row-reverse",
        "& .MuiToolbar-root": {
          flexWrap: "wrap",
          justifyContent: "center",
        },
      },
    },

    MuiSelect: {
      icon: {
        color: colors.primary,
      },
    },

    MuiOutlinedInput: {
      root: {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: colors.gray,
        },
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.lightGray,
          },
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primary,
            borderWidth: "1px",
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.primary,
              borderWidth: "1px",
            },
          },
        },
      },
    },
  },
});

export default theme;
