export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const _exception = config.url.split(":")[0];
      const isToken = _exception === "https" ? false : true;
      if (isToken) {
        config.baseUrl = process.env.REACT_APP_API_URL;
        config.url = `${config.baseUrl}${config.url}`;
        config.headers["Accept-Language"] =
          localStorage.getItem("i18nextLng") === "en" ? "en_US" : "ar_AE";
        const {
          auth: { token },
        } = store.getState();

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
}
