import React from "react";
import { Container } from "@material-ui/core";
import Header from "./Header";
const MainLayout = ({ children }) => {
  return (
    <Container maxWidth="xl">
      <Header />
      {children}
    </Container>
  );
};

export default MainLayout;
