import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
// import CustomMenu from "./custom-menu";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuIcon from "@material-ui/icons/Menu";
import { useSelector } from "react-redux";
import colors from "../../../constant/colors";
import MenuDrawer from "./MenuDrawer";
const Header = () => {
  const { t } = useTranslation();
  // const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  // const handleCloseMenu = () => setAnchorEl(null);
  const user = useSelector((state) => {
    return state.auth.user;
  });
  const [anchorUser, setAnchorUser] = React.useState(null);
  const handleClick = (event) => {
    setAnchorUser(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorUser(null);
  };

  const HeaderMemo = useMemo(() => {
    return (
      <Box mt={1} display="flex" alignItems="center" flexWrap="wrap">
        <Box flexGrow={1}>
          <Tooltip title="Menu" arrow>
            <IconButton
              style={{
                color: colors.lightBlue,
              }}
              onClick={(e) => {
                setOpenDrawer(true);
                // setAnchorEl(e.target);
              }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box display="flex" alignItems="center">
          <Box pr={4}>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              style={{
                background: colors.lightBlue,
                color: colors.white,
              }}
              onClick={handleClick}
            >
              {user && user.firstName + " " + user.lastName}
            </Button>

            <Menu
              id="customized-menu"
              anchorEl={anchorUser}
              keepMounted
              open={Boolean(anchorUser)}
              onClose={handleClose}
            >
              <MenuItem>
                <Link
                  style={{
                    textDecoration: "none",
                  }}
                  to="/logout"
                >
                  <Button>{t("logout")}</Button>
                </Link>
              </MenuItem>
            </Menu>
          </Box>
          <Box pt={1}>
            <Link to="/">
              <img
                width="75px"
                alt="green chain log"
                src="/images/green-chain-logo.png"
              />
            </Link>

            {/* <CustomMenu anchorEl={anchorEl} onClose={handleCloseMenu} /> */}
            <MenuDrawer
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
            />
          </Box>
        </Box>
      </Box>
    );
  }, [anchorUser, t, user, openDrawer]);

  return HeaderMemo;
};

export default Header;
