import React, { useCallback, useMemo } from "react";
import { Link, NavLink } from "react-router-dom";
import { Typography, makeStyles, useTheme } from "@material-ui/core";
import { checkIsActive } from "../../../helpers/routerHelper";
import { useLocation } from "react-router";
import colors from "../../../constant/colors";
import PropTypes from "prop-types";
const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    // color: `${theme.palette.text.primary} !important`,
    "&:hover": {
      textDecoration: "none",
    },
  },
  bold: {
    fontWeight: "bold",
  },
  active: {
    color: `${colors.lightBlue} !important`,
  },
}));

const CustomLink = ({
  name,
  to,
  color,
  isnavlink = false,
  isHeadingLink = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  const handleClick = useCallback(
    (e) => {
      if (isActive) e.preventDefault();
    },
    [isActive]
  );

  const CustomLinkMemo = useMemo(() => {
    return isnavlink ? (
      <NavLink
        to={to}
        className={[classes.link, isActive && classes.active].join(" ")}
        style={{ color: color ? color : theme.palette.text.primary }}
        onClick={handleClick}
      >
        {isHeadingLink ? (
          <Typography className={classes.bold} variant="body1" gutterBottom>
            {name}
          </Typography>
        ) : (
          <Typography variant="body2" gutterBottom>
            {name}
          </Typography>
        )}
      </NavLink>
    ) : (
      <Link
        to={to}
        className={classes.link}
        style={{ color: color ? color : theme.palette.text.primary }}
      >
        {isHeadingLink ? (
          <Typography className={classes.bold} variant="body1">
            {name}
          </Typography>
        ) : (
          <Typography variant="body2">{name}</Typography>
        )}
      </Link>
    );
  }, [
    classes.active,
    classes.bold,
    classes.link,
    color,
    handleClick,
    isActive,
    isHeadingLink,
    isnavlink,
    name,
    theme.palette.text.primary,
    to,
  ]);

  return CustomLinkMemo;
};

CustomLink.propTypes = {
  isnavlink: PropTypes.bool.isRequired,
  isHeadingLink: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default CustomLink;
