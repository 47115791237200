import { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import * as auth from "./AuthRedux";
import { getUserByToken } from "./AuthCRUD";
import { useOnMount } from "../../../components/hooks/useOnMount";
import Spinner from "../../../components/spinner";

const AuthInit = ({ children }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const token = useSelector(({ auth }) => auth.token, shallowEqual);

  useOnMount(() => {
    const requestUser = async () => {
      try {
        const { data: user } = await getUserByToken();
        dispatch(auth.actions.fulfillUser(user));
      } catch (error) {
        dispatch(auth.actions.logout());
      } finally {
        setLoader(false);
      }
    };
    if (token) {
      requestUser();
    } else {
      dispatch(auth.actions.logout());
      setLoader(false);
    }
  });
  return loader ? <Spinner open={true} /> : <>{children}</>;
};

export default AuthInit;
