import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routing/routes";
import AuthInit from "./modules/auth/redux/AuthInit";
import Spinner from "./components/spinner";
function App({ basename }) {
  return (
    <Suspense fallback={<Spinner open={true} />}>
      <BrowserRouter basename={basename}>
        <AuthInit>
          <Routes />
        </AuthInit>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
